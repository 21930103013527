/**
 | Encrypt and Decrypt a single value.
 | Usage example:
 |   let crypt = new Crypt('password');
 |   crypt.encrypt('test').then(encrypted => {
 |      console.log(encrypted); // => hsu18shldd83hdsla92
 |
 |      crypt.decrypt(encrypted).then(decrypted => {
 |          console.log(decrypted); // => test
 |      });
 |   });
 */
export default class Crypt {
  constructor(secret) {
    // set passwort / secret via constructor
    this.secret = secret;
  }

  /**
   * When a native TextEncoder implementation is not available, the most light-weight solution would be this,
   * because in addition to being much faster, it also works in IE9 "out of the box". We avoid using the
   * browsers implementation of TextEncoder() due to problems when user switches between browsers.
   * See: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_3_%E2%80%93_JavaScript's_UTF-16_%3E_binary_string_%3E_base64
   * @param sString
   * @returns {Uint8Array}
   * @private
   */
  _encodeText(sString) {
    // if (window.TextEncoder) {
    //     return new TextEncoder().encode(sString);
    // }
    let aUTF16CodeUnits = new Uint16Array(sString.length);
    Array.prototype.forEach.call(aUTF16CodeUnits, function (el, idx, arr) {
      arr[idx] = sString.charCodeAt(idx);
    });
    return new Uint8Array(aUTF16CodeUnits.buffer);
  }

  /**
   * When a native TextDecoder implementation is not available, the most light-weight solution would be this,
   * because in addition to being much faster, it also works in IE9 "out of the box". We avoid using the
   * browsers implementation of TextDecoder() due to problems when user switches between browsers.
   * See: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#Solution_3_%E2%80%93_JavaScript's_UTF-16_%3E_binary_string_%3E_base64
   * @param aBinaryView
   * @returns {string}
   * @private
   */
  _decodeText(aBinaryView) {
    // if (window.TextDecoder) {
    //     return new TextDecoder().decode(sString);
    // }
    var aUTF16CodeUnits = new Int16Array(aBinaryView);
    return String.fromCharCode.apply(null, aUTF16CodeUnits);
  }

  /**
   * Setter shorthand
   * @param secret
   */
  set secret(secret) {
    // encode secret as UTF-8
    this._secret = this._encodeText(secret);
  }

  /**
   * Getter shorthand
   * @returns {Uint8Array|*}
   */
  get secret() {
    return this._secret;
  }

  _getHashedSecret() {
    // hash the secret
    return crypto.subtle.digest("SHA-256", this.secret);
  }

  /**
   * Encrypt plaintext
   * @param plaintext
   * @returns {Promise<* | undefined | never>}
   */
  async encrypt(plaintext) {
    if (plaintext === null) return null;
    else if (plaintext.length === 0) return null;

    return this._encrypt(plaintext).then((encrypted) => {
      return this._encrypt(encrypted);
    });
  }

  /**
   * @param {Object} data
   * @param {array} keys
   * @return {Object}
   */
  async encryptData(data, keys) {
    return Promise.all(
      keys.flatMap(async (key) => {
        return [[key], await this.encrypt(data[key])];
      }),
    ).then((result) => {
      return Object.fromEntries(result);
    });
  }

  /**
   * Decrypt ciphertext
   * @param ciphertext
   * @returns {Promise<* | undefined | never>}
   */
  async decrypt(ciphertext) {
    // we do not need to decrypt null
    if (ciphertext === null) return null;

    return this._decrypt(ciphertext)
      .then((decrypted) => {
        return this._decrypt(decrypted);
      })
      .catch((error) => {
        if (!this.strictMode) {
          // fallback when decrypting an unencrypted ciphertext
          // (this might happen if client encryption had been
          // activated subsequently after saving first items)
          return ciphertext;
        }
        // but when in strictMode, we do not fall back!
        throw error;
      });
  }

  async _encrypt(plaintext) {
    try {
      // get 128-bit random iv for AES-CBC
      const iv = crypto.getRandomValues(new Uint8Array(16));

      // specify algorithm to use
      const alg = {name: "AES-CBC", iv: iv};

      // get hashed secret
      const hs = await this._getHashedSecret();

      // generate key from pw
      const key = await crypto.subtle.importKey("raw", hs, alg, false, ["encrypt"]);

      // encode plaintext as UTF-8
      const ptUint8 = this._encodeText(plaintext);

      // encrypt plaintext using key
      const ctBuffer = await crypto.subtle.encrypt(alg, key, ptUint8);

      // ciphertext as byte array
      const ctArray = Array.from(new Uint8Array(ctBuffer));

      // ciphertext as string
      const ctStr = ctArray.map((byte) => String.fromCharCode(byte)).join("");

      // encode ciphertext as base64
      const ctBase64 = btoa(ctStr);

      // iv as hex string
      const ivHex = Array.from(iv)
        .map((b) => ("00" + b.toString(16)).slice(-2))
        .join("");

      // return iv+ciphertext
      return ivHex + ctBase64;
    } catch (err) {
      throw new Error("[CRYPT service] Cannot encrypt with message: " + err.message);
    }
  }

  async _decrypt(ciphertext) {
    try {
      // get iv from ciphertext
      const iv = ciphertext
        .slice(0, 32)
        .match(/.{2}/g)
        .map((byte) => parseInt(byte, 16));

      // specify algorithm to use
      const alg = {name: "AES-CBC", iv: new Uint8Array(iv)};

      // get hashed secret
      const hs = await this._getHashedSecret();

      // use hs to generate key
      const key = await crypto.subtle.importKey("raw", hs, alg, false, ["decrypt"]);

      // decode base64 ciphertext
      const ctStr = atob(ciphertext.slice(32));

      // ciphertext as Uint8Array
      const ctUint8 = new Uint8Array(ctStr.match(/[\s\S]/g).map((ch) => ch.charCodeAt(0)));

      // decrypt ciphertext using key
      const plainBuffer = await crypto.subtle.decrypt(alg, key, ctUint8);

      // decode plaintext from UTF-8
      return this._decodeText(plainBuffer);
    } catch (err) {
      throw new Error("[CRYPT service] Cannot decrypt with message: " + err.message);
    }
  }

  /**
   * Return hexadecimal sha256 hash of input text
   * @param text
   * @returns {Promise<string>}
   */
  async sha256(text) {
    let buffer = await crypto.subtle.digest("SHA-256", this._encodeText(text));
    let byteArray = new Uint8Array(buffer);

    let hexCodes = [...byteArray].map((value) => {
      const hexCode = value.toString(16);
      return hexCode.padStart(2, "0");
    });

    return hexCodes.join("");
  }

  /**
   * Setter shorthand
   * @param status
   */
  set strictMode(status) {
    this._strictMode = status;
  }

  /**
   * Getter shorthand
   * @returns {Boolean}
   */
  get strictMode() {
    // strictMode defaults to false
    return this._strictMode || false;
  }

  /**
   * Enable strict mode in this instance
   */
  enableStrictMode() {
    this.strictMode = true;
  }
}
